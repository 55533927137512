import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router';
import {
  CircularProgress,
  SvgIcon,
  Popover,
  Snackbar,
} from '@material-ui/core';
import {
  Search,
  Box,
} from 'react-feather';
import { setOrderId } from '../../actions/deliveryActions';
import Page from '../../components/Layout/Page';
import './ExpressDelivery.css';
import {
  DELIVERY_STATUS_PENDIENTE,
  DELIVERY_STATUS_ENTREGADO,
  DELIVERY_STATUS_EN_RUTA,
  STATUS_BILLING_PROCESSING,
} from '../../utils/const';
import axios from '../../utils/axios';

function ExpressDelivery() {
  const searchInput = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const customer = useSelector((state) => state.customer);
  const user = useSelector((state) => state.account.user);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorCheck, setErrorCheck] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [openSanckBar, setopenSanckBar] = useState(false);

  const viewDetails = (orderId) => {
    dispatch(setOrderId(orderId));
    history.push('/orden-detalles');
  };

  const handleSnackBarClose = () => {
    setopenSanckBar(false);
  };
  useEffect(() => {
    const getExpressOrders = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/orders/checkout/express?store=${user.store_id}`);
        setOrders(response.data.data.orders);
      } catch (err) {
        setOrders([]);
      } finally {
        setLoading(false);
      }
    };
    getExpressOrders();
  }, []);

  const handDeliver = async (order) => {
    let status = '';
    switch (order.delivery_time) {
      case 'immediate':
        status = DELIVERY_STATUS_ENTREGADO;
        break;
      case 'programmed':
        status = DELIVERY_STATUS_EN_RUTA;
        break;
      default:
        status = '';
        break;
    }
    const response = await axios.put('/api/delivery/status', {
      order_id: order.id,
      delivery_status: status,
    });
    if (response.data.data.order) {
      setopenSanckBar(true);
      window.location.href = '/entrega-inmediata';
    } else {
      setErrorCheck('Problema al intentar cambiar el estado');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckCode = async () => {
    try {
      setErrorCheck('');
      if (!selectedOrder.pickup_code) {
        setErrorCheck('Debe ingresar un codigo de pickup');
      } else {
        const response = await axios.get(`/api/orders/pickup/${searchQuery}`);
        const { order } = response.data.data;
        if (order && selectedOrder.id === order.id) {
          await handDeliver(order);
        } else {
          setErrorCheck('Codigo invalido');
        }
      }
    } catch (err) {
      setErrorCheck('Problema al intentar obtener el código');
    }
  };

  useEffect(() => {
    if (selectedOrder) {
      setOpen(true);
    }
  }, [selectedOrder]);

  const stateName = (type) => {
    let state = '';
    switch (type) {
      case 'delivery':
        state = 'Despacho';
        break;
      case 'immediate':
        state = 'Inmediato';
        break;
      case 'pickup':
        state = 'Retiro';
        break;
      case 'programmed':
        state = 'Programado';
        break;
      default:
        break;
    }
    return state;
  };

  const createRows = () => orders.map((order) => ([
    <div>
      <div className="info">
        <p>{order.order_number}</p>
        {(order.UberOrder) ? (
          <p>
            {order.UberOrder
              .estimate_id.substr(order.UberOrder.estimate_id.length - 5)
              .toUpperCase()}

          </p>
        ) : ''}
      </div>
      <div className="info">
        <p>{stateName(order.delivery_type)}</p>
        <p>{stateName(order.delivery_time)}</p>
      </div>
      <div className="info">
        {order.Customer
          ? (
            <div>
              <p>{order.Customer.name ? order.Customer.name : 'Sin Nombre'}</p>
              <p>{order.Customer.rut ? order.Customer.rut : 'Sin RUT'}</p>
            </div>
          )
          : (
            <div>
              {order.buy_type === 'rappi' ? 'Rappi' : 'Cliente no registrado'}
            </div>
          )}
      </div>
      <div className="info-button">
        <button
          disabled={(order.delivery_time === 'programmed')}
          type="button"
          className={(order.delivery_time === 'programmed') ? 'grey-button' : 'yellow-button'}
          onClick={() => viewDetails(order.id)}
        >
          <SvgIcon fontSize="small">
            <Search />
          </SvgIcon>
        </button>
        <button
          disabled={
            (
              DELIVERY_STATUS_PENDIENTE === order.delivery_status
              && order.delivery_time === 'immediate'
            )
            || order.delivery_type === 'delivery'
          }
          type="button"
          className={
            (
              DELIVERY_STATUS_PENDIENTE === order.delivery_status
              && order.delivery_time === 'immediate'
            )
            || order.delivery_type === 'delivery'
              ? 'grey-button'
              : 'green-button'
          }
          onClick={() => setSelectedOrder(order)}
        >
          <SvgIcon fontSize="small">
            <Box />
          </SvgIcon>
        </button>
      </div>
    </div>,
    <hr />,
  ]));

  if (customer.order_id
    && (customer.order_status === STATUS_BILLING_PROCESSING)) {
    return (<Redirect to="pago-final" />);
  }

  return (
    <>
      <Page
        title="Club Líquidos | POS Pedidos Express"
        progressStep="compra"
        backRef="/inicio"
      >
        <div className="delivered-orders-table">
          <h3 className="yellow-title-delivered-orders">Pedidos por Entregar</h3>
          <div className="scrollable">
            <div className="order-number-cell">Nº orden</div>
            <div className="order-number-cell">Tipo</div>
            <div className="order-number-cell">Cliente</div>
            <div className="button-size-spacer" />
          </div>
          <hr className="scrollable-hr" />
          {!loading ? createRows() : <CircularProgress />}
        </div>

        <Popover
          open={open}
          onClose={() => handleClose()}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        >
          <div className="cart-popover-container">
            <div className="popover-cart-info-message">
              <p>Ingresa el código </p>
              <div className="search-products-input">
                <input
                  ref={searchInput}
                  type="text"
                  placeholder="CODIGO CORREO"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="cart-popup-confirmation-buttons">
              <button
                className="accept-cart-button"
                type="submit"
                onClick={() => handleCheckCode()}
              >
                Aceptar
              </button>
              <button
                className="cancel-button"
                type="submit"
                onClick={() => handleClose()}
              >
                Cancelar
              </button>
            </div>
            {(errorCheck) ? (
              <div className="error-message">
                <p>{errorCheck}</p>
              </div>
            ) : ''}
          </div>
        </Popover>
        <Snackbar
          message="Entrega exitosa!"
          open={openSanckBar}
          onRequestClose={() => handleSnackBarClose}
          autoHideDuration={2000}
        />
      </Page>
    </>
  );
}

export default ExpressDelivery;
