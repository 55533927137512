/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CircularProgress, Dialog, DialogTitle, DialogContent, makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import thousandSeparator from '../../utils/thousandSeparator';
import ChangePayMethod from './changeMethod/ChangePayMethod';
import CancelPay from './cancelPay/CancelPay';
import axios from '../../utils/axios';
import './Payment.css';
import { setPaymentLoading } from '../../actions/customerActions';
import { STATUS_APROBADO, STATUS_CANCELADO, STATUS_RECHAZADO } from '../../utils/const';

const useStyles = makeStyles(() => ({
  paper: {
    border: '1px solid #e6d51b',
  },
}));

function CardPayment({
  handlePayed,
  payed,
  total,
  type,
  step,
  paids,
  paymentStep,
  payments,
  setPayments,
  totalSteps,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { payment_loading: paymentLoading } = useSelector((state) => state.customer);
  const { Store: store } = useSelector((state) => state.account.user);

  // const [loading, setLoading] = useState(false);
  // const [amountPayed, setAmountPayed] = useState(null);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [cancelButton, setCancelButton] = useState(false);
  const [smartPOSCancelButton, setSmartPOSCancelButton] = useState(false);
  const [rdcPaymentId, setRDCPaymentId] = useState(null);
  // const [authCodeButton, setAuthCodeButton] = useState(false);
  const [authCodeForm, setAuthCodeForm] = useState(false);
  const [authCode, setAuthCode] = useState(null);
  const [authCodeError, setAuthCodeError] = useState(null);
  const [isBack, setIsBack] = useState(false);
  const [transbankCode, setTransbankCode] = useState(null);
  const [transbankCodeCheck, setTransbankCodeCheck] = useState(null);
  const [inputOpen, setInputOpen] = useState(false);
  const isMercadopagoMachine = useSelector((state) => state.session.isMercadopagoMachine);
  const orderNumber = useSelector((state) => state.customer.order_number);

  window.onpopstate = () => {
    setIsBack(true);
  };

  useEffect(() => {
    if (payed) {
      handlePayed(null);
    }
  }, [payed, handlePayed]);

  const orderId = useSelector((state) => state.customer.order_id);
  const { machineNumber } = useSelector((state) => state.session);

  const confirmPayment = async (rdcId) => {
    let error = null;
    let maxCount = 0;

    while (!paymentConfirmed) {
      // eslint-disable-next-line no-await-in-loop
      const resp = await axios.get(`/api/payments/redelcom/${rdcId}`);
      if (resp.status === 200) {
        const { code, data } = resp.data;

        if (code === 200) {
          setPaymentConfirmed(true);

          let newPayment = false;

          if (data.ESTADO === 'APROBADO') {
            error = null;
          } else if (data.ESTADO === 'RECHAZADO') {
            error = `PAGO REDELCOM RECHAZADO: ${data.MENSAJE_VISOR}`;
            newPayment = true;
          } else if (data.ESTADO === 'ERROR') {
            error = `ERROR REDELCOM: ${data.MENSAJE_VISOR}`;
            newPayment = true;
          } else {
            error = 'ERROR REDELCOM';
          }

          // Save the new payment
          if (newPayment) {
            newPayment = data.payment;
            // If the payment has failed, replace de current payment with the new one
            const newPayments = [...payments];
            newPayments[step] = {
              id: newPayment.id,
              order_id: newPayment.order_id,
              type: newPayment.type,
              amount: newPayment.amount,
              status: newPayment.status,
              deposit_id: null,
              next: false,
              payed: false,
              paymentType: newPayment.type,
              rdc_transaction_id: newPayment.rdc_transaction_id,
            };
            setPayments(newPayments);
          }

          break;
        } else if (code === 204) {
          setPaymentConfirmed(false);
        } else {
          setPaymentConfirmed(true);
          error = 'Ha ocurrido un error. Por favor intente nuevamente.';
          break;
        }
      } else {
        setPaymentConfirmed(true);
        error = 'Ha ocurrido un error. Por favor intente nuevamente.';
        break;
      }

      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 1000));
      maxCount += 1;

      if (maxCount >= 90) {
        setCancelButton(true);
      }
      // if (maxCount >= 1) {
      //   setAuthCodeButton(true);
      // }
    }

    dispatch(setPaymentLoading(false));
    // setLoading(false);
    handlePayed(error);
  };

  const handleRedelcomPayment = async () => {
    dispatch(setPaymentLoading(true));
    // setLoading(true);
    const resp = await axios.post('/api/payments/redelcom/new', {
      order_id: orderId,
      machine_id: machineNumber,
      amount: total,
      payment_type: type,
      payment_id: paymentStep.id,
    });

    const { payment, errorMessage, errorAction } = resp.data.data;
    if (!payment) {
      dispatch(setPaymentLoading(false));
      if (errorAction === 'reset') {
        handlePayed(errorMessage, errorAction);
      } else {
        handlePayed(errorMessage || 'Ha ocurrido un error. Por favor intente nuevamente.');
      }
    } else {
      setRDCPaymentId(payment.rdc_transaction_id);
      confirmPayment(payment.rdc_transaction_id);
    }
  };

  const handleTransbankPayment = async (e) => {
    e.preventDefault();

    if (transbankCode !== transbankCodeCheck) {
      const key = enqueueSnackbar('Los códigos no son iguales', {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return;
    }

    dispatch(setPaymentLoading(true));
    // setLoading(true);
    try {
      await axios.post('/api/payments/transbank/new', {
        order_id: orderId,
        payment_id: paymentStep.id,
        transbank_code: transbankCode,
      });
      handlePayed(null);
    } catch (error) {
      const errorMessage = error.response?.data?.errorMessage || error.message;
      const errorAction = error.response?.data?.errorAction;
      if (errorAction === 'reset') {
        handlePayed(errorMessage, errorAction);
      } else {
        handlePayed(errorMessage);
      }
    } finally {
      dispatch(setPaymentLoading(false));
      // setLoading(false);
    }
  };

  const handleSmartPOSPayment = async () => {
    const smartPOSCookie = document.cookie.split('; ').find((row) => row.startsWith('smart_pos_keys='))?.split('=')[1];
    const smartPOSCookieBool = smartPOSCookie === 'true';
    if (!smartPOSCookieBool) {
      const errorMessage = 'No están cargadas las llaves del SmartPOS';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else if (window.AppPagoSDK) {
      dispatch(setPaymentLoading(true));
      window.AppPagoSDK.requestForSales(`${total}`);

      let count = 0;
      while (paymentLoading) {
        await new Promise((res) => setTimeout(res, 1000));
        count += 1;

        if (count === 10) {
          setSmartPOSCancelButton(true);
        }
      }
    }
  };

  const cancel = () => {
    setPaymentConfirmed(true);
    handlePayed('Operación cancelada');
  };

  const handleChange = (event) => {
    setAuthCode(event.target.value);
  };

  const handleAuthCode = async () => {
    if (!authCode) {
      setAuthCodeError('Debe ingresar un codigo');
    } else {
      setAuthCodeError(null);
      await axios.post(`/api/payments/redelcom/${rdcPaymentId}/authCode`, {
        authCode,
      });
      setAuthCodeForm(false);
      setPaymentConfirmed(true);
      handlePayed(null);
    }
  };

  const handleMercadoPagoMachinePayment = async () => {
    if (typeof PaymentConfigBuilder !== 'undefined') {
      const transactionId = `order-${orderNumber}-${paymentStep.id}`;

      const checkCallback = async (result, error, paymentCallback = {}) => {
        let paymentStatus = null;

        if (result?.status === 'approved') {
          paymentStatus = STATUS_APROBADO;
        } else if (result?.status === 'canceled') {
          paymentStatus = STATUS_CANCELADO;
        } else {
          paymentStatus = STATUS_RECHAZADO;
        }

        let updateSuccess = false;
        let count = 0;
        while (count < 10 && !updateSuccess) {
          try {
            const machineTransactionId = paymentCallback?.result?.reference;
            const { data } = await axios.put('/api/payments/mercadopago/machine', {
              payment_id: paymentStep.id,
              machineTransactionId,
              status: paymentStatus,
              checkCallback: { result, error },
              paymentCallback,
            });

            if (data?.data?.payment) {
              const newPayment = data.data.payment;
              const newPayments = [...payments];
              newPayments[step] = {
                id: newPayment.id,
                order_id: newPayment.order_id,
                type: newPayment.type,
                amount: newPayment.amount,
                status: newPayment.status,
                deposit_id: null,
                next: false,
                payed: false,
                paymentType: newPayment.type,
                rdc_transaction_id: null,
                smart_pos_transaction_id: null,
              };
              setPayments(newPayments);
            }
            updateSuccess = true;
          } catch (err) {
            await new Promise((res) => setTimeout(res, 1000));
          }
          count += 1;
        }

        dispatch(setPaymentLoading(false));

        if (paymentStatus === STATUS_APROBADO) {
          handlePayed(null);
        } else if (paymentCallback?.error) {
          handlePayed('Error en el proceso de pago. (paymentCallback)');
          const key = enqueueSnackbar(JSON.stringify(paymentCallback.error), {
            variant: 'error',
            onClick: () => {
              closeSnackbar(key);
            },
          });
        } else if (error) {
          handlePayed('Error en el proceso de pago. (checkCallback)');
          const key = enqueueSnackbar(JSON.stringify(error), {
            variant: 'error',
            onClick: () => {
              closeSnackbar(key);
            },
          });
        } else {
          handlePayed('Error al actualizar el pago.');
        }
      };

      const paymentResultCallback = async (result, error) => {
        const paymentCallback = { result, error };

        // eslint-disable-next-line no-undef
        launchPaymentCheckExternalReferenceStatus(
          transactionId,
          (res, err) => checkCallback(res, err, paymentCallback),
        );
      };

      // eslint-disable-next-line no-undef
      const config = new PaymentConfigBuilder();

      // Configuración del monto y metadatos
      config.setAmount(parseFloat(total));
      config.setDescription(transactionId);
      config.setPrintOnTerminal(false);

      // Lanzar el flujo de pago
      try {
        dispatch(setPaymentLoading(true));
        // eslint-disable-next-line no-undef
        launchPaymentCallback(config, paymentResultCallback);
      } catch (error) {
        dispatch(setPaymentLoading(false));
        enqueueSnackbar('Error al iniciar el flujo de pago con MercadoPago.', {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('Problemas con la configuración de MercadoPago.', {
        variant: 'error',
      });
    }
  };

  const paymentMachineSelector = () => {
    if (isMercadopagoMachine) {
      return (
        <button disabled={paymentLoading} type="button" className="pay-button" onClick={() => handleMercadoPagoMachinePayment()}>
          Pagar con Mercadopago
        </button>
      );
    }
    return (
      <button disabled={paymentLoading} type="button" className="pay-button" onClick={() => handleRedelcomPayment()}>
        Pagar con Redelcom
      </button>
    );
  };

  return (
    <div>
      {paymentLoading ? (
        <div>
          <Dialog
            open={authCodeForm}
            classes={{ paper: classes.paper }}
          >
            <DialogTitle className="auth-code-form" id="alert-dialog-title">Codigo de autorización</DialogTitle>
            <DialogContent className="auth-code-form auth-code-form-content">
              <input
                type="text"
                placeholder="codigo de autorización"
                className="auth-code-input"
                name="authCode"
                value={authCode}
                onChange={(e) => handleChange(e)}
              />
              {authCodeError && <span className="error-message auth-code-error-message">{authCodeError}</span>}
              <div className="auth-code-form-row-buttons">
                <button type="button" className="yellow-button auth-code-button" onClick={() => handleAuthCode()}>Aceptar</button>
                <button type="button" className="auth-code-cancel-button auth-code-button" onClick={() => cancel()}>Cancelar</button>
              </div>
            </DialogContent>
          </Dialog>
          <h1 className="waiting-payment-header">Esperando Pago</h1>
          <div
            style={{
              margin: '0 auto', display: 'flex', flexDirection: 'row', justifyContent: 'center',
            }}
          >
            <CircularProgress style={{ color: 'var(--global--secondary--BackgroundColor' }} />
          </div>
          <div>
            {cancelButton && (
              <p className="cancel-payment-button">
                ¿No abre la aplicación de pago?
                <div className="cancel-payment-button">
                  <CancelPay paids={paids} />
                </div>
              </p>
            )}
            {smartPOSCancelButton && (
              <button
                className="cancel-payment-button"
                type="button"
                onClick={() => dispatch(setPaymentLoading(false))}
              >
                Cancelar SmartPOS
              </button>
            )}
          </div>
          <div>
            {/* {authCodeButton && (
              <p className="auth-payment-button">
                <button type="button" onClick={() => setAuthCodeForm(true)}>
                  Ingrese codigo de autorización
                </button>
              </p>
            )} */}
          </div>
        </div>
      ) : (
        <div>
          <h1>{`Pago ${type !== 'WALLET' ? type : 'OTRO'}`}</h1>
          <h2>
            $
            {thousandSeparator(total, '.')}
          </h2>
          {inputOpen ? (
            <form onSubmit={handleTransbankPayment} className="transbank-form">
              <input
                className="transbank-input"
                placeholder="Código Voucher Transbank"
                value={transbankCode}
                onChange={(e) => setTransbankCode(e.target.value)}
                required
              />
              <input
                className="transbank-input"
                placeholder="Vuelva a ingresar el código"
                value={transbankCodeCheck}
                onChange={(e) => setTransbankCodeCheck(e.target.value)}
                required
              />
              <button type="submit" className="pay-button">Pagar</button>
            </form>
          ) : (
            <>
              <div className="tabs-payment">
                {window.AppPagoSDK ? (
                  <>
                    {store.tbk_smart_pos_active ? (
                      <button
                        disabled={paymentLoading}
                        type="button"
                        className="pay-button"
                        onClick={() => handleSmartPOSPayment()}
                        style={{ fontWeight: 'bold' }}
                      >
                        PAGAR
                      </button>
                    ) : <span className="error-message">Tienda no habilitada</span>}
                  </>
                ) : paymentMachineSelector()}
              </div>
              {!window.AppPagoSDK ? (
                <div className="tabs-payment">
                  <button disabled={paymentLoading} type="button" className="pay-button" onClick={() => setInputOpen(true)}>
                    Pagar con Transbank
                  </button>
                </div>
              ) : null}
            </>
          )}
          <div className="tabs-payment">
            {(!isBack)
              ? <ChangePayMethod step={step} payments={payments} setPayments={setPayments} />
              : ''}
            <CancelPay paids={paids} />
          </div>
          <div className="tabs-payment">
            {`Paso ${step + 1} de ${totalSteps}`}
          </div>
        </div>
      )}
    </div>
  );
}

export default CardPayment;
