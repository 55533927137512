/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import axios from '../../utils/axios';
import thousandSeparator from '../../utils/thousandSeparator';
import {
  MERCADOPAGO_PROVIDER,
  REDELCOM_PROVIDER,
  TRANSBANK_PROVIDER,
  TRANSBANK_SMARTPOS_PROVIDER,
} from '../../utils/const';
import { setPaymentLoading } from '../../actions/customerActions';

function CornershopPayment({
  handlePayed,
  payed,
  total,
  paymentStep,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { payment_loading: paymentLoading } = useSelector((state) => state.customer);
  const { Store: store } = useSelector((state) => state.account.user);

  const [cornershopCode, setCornershopCode] = useState(null);
  const orderId = useSelector((state) => state.customer.order_id);
  const { machineNumber, isMercadopagoMachine } = useSelector((state) => state.session);
  // const [loading, setLoading] = useState(false);
  const [inputOpen, setInputOpen] = useState(false);
  const [transbankCode, setTransbankCode] = useState(null);
  const [codeError, setCodeError] = useState(null);

  useEffect(() => {
    if (payed) {
      handlePayed(null);
    }
  }, [payed, handlePayed]);

  const confirmPayment = async (rdcId) => {
    let paymentConfirmed = false;
    let error = null;

    while (!paymentConfirmed) {
      // eslint-disable-next-line no-await-in-loop
      const resp = await axios.get(`/api/payments/redelcom/${rdcId}`);
      if (resp.status === 200) {
        const { code, data } = resp.data;

        if (code === 200) {
          paymentConfirmed = true;

          if (data.ESTADO === 'RECHAZADO') {
            error = 'El pago fue rechazado.';
          }
        } else if (code === 204) {
          paymentConfirmed = false;
        } else {
          paymentConfirmed = true;
          error = 'Ha ocurrido un error. Por favor intente nuevamente.';
        }
      } else {
        paymentConfirmed = true;
        error = 'Ha ocurrido un error. Por favor intente nuevamente.';
      }

      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 1500));
    }

    handlePayed(error);
  };

  const handleSmartPOSPayment = () => {
    const smartPOSCookie = document.cookie.split('; ').find((row) => row.startsWith('smart_pos_keys='))?.split('=')[1];
    const smartPOSCookieBool = smartPOSCookie === 'true';
    if (!smartPOSCookieBool) {
      const errorMessage = 'No están cargadas las llaves del SmartPOS';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else if (window.AppPagoSDK) {
      dispatch(setPaymentLoading(true));
      // setLoading(true);
      window.AppPagoSDK.requestForSales(`${total}`);
    }
  };

  const handleTransbankPayment = async () => {
    dispatch(setPaymentLoading(true));
    // setLoading(true);
    try {
      await axios.post('/api/payments/transbank/new', {
        order_id: orderId,
        payment_id: paymentStep.id,
        transbank_code: transbankCode,
      });
      handlePayed(null);
    } catch (error) {
      const errorMessage = error.response?.data?.errorMessage || error.message;
      handlePayed(errorMessage);
    } finally {
      dispatch(setPaymentLoading(false));
      // setLoading(false);
    }
  };

  const handleSubmit = async (provider) => {
    dispatch(setPaymentLoading(true));
    // setLoading(true);

    if (!cornershopCode || cornershopCode === '') {
      dispatch(setPaymentLoading(false));
      // setLoading(false);
      setCodeError('Por favor ingrese Código Cornershop');
      return;
    }

    try {
      const resp = await axios.post('/api/payments/cornershop/new', {
        order_id: orderId,
        code: cornershopCode,
        machine_number: machineNumber,
        paymentId: paymentStep.id,
        provider,
        transbank_code: transbankCode,
      });

      if (provider === REDELCOM_PROVIDER) {
        const { payment } = resp.data.data;

        await confirmPayment(payment.rdc_transaction_id);
      }

      if (provider === TRANSBANK_SMARTPOS_PROVIDER) {
        handleSmartPOSPayment();
      }

      if (provider === TRANSBANK_PROVIDER) {
        await handleTransbankPayment();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.errorMessage || error.message;
      handlePayed(errorMessage);
    } finally {
      dispatch(setPaymentLoading(false));
      // setLoading(false);
    }
  };

  const payButton = () => {
    if (window.AppPagoSDK) {
      return (
        <div style={{ marginTop: '5px' }}>
          <div className="tabs-payment">
            {store.tbk_smart_pos_active ? (
              <button
                disabled={paymentLoading}
                type="button"
                className="pay-button"
                onClick={() => handleSubmit(TRANSBANK_SMARTPOS_PROVIDER)}
                style={{ fontWeight: 'bold' }}
              >
                PAGAR
              </button>
            ) : <span className="error-message">Tienda no habilitada</span>}
          </div>
        </div>
      );
    }

    if (isMercadopagoMachine) {
      return (
        <div style={{ marginTop: '5px' }}>
          <div className="tabs-payment">
            <button disabled={paymentLoading} type="button" className="pay-button" onClick={() => handleSubmit(MERCADOPAGO_PROVIDER)}>
              Pagar con MercadoPago
            </button>
          </div>
          <div className="tabs-payment">
            <button disabled={paymentLoading} type="button" className="pay-button" onClick={() => setInputOpen(true)}>
              Pagar con Transbank
            </button>
          </div>
        </div>
      );
    }

    return (
      <div style={{ marginTop: '5px' }}>
        <div className="tabs-payment">
          <button disabled={paymentLoading} type="button" className="pay-button" onClick={() => handleSubmit(REDELCOM_PROVIDER)}>
            Pagar con Redelcom
          </button>
        </div>
        <div className="tabs-payment">
          <button disabled={paymentLoading} type="button" className="pay-button" onClick={() => setInputOpen(true)}>
            Pagar con Transbank
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {paymentLoading ? (
        <div>
          <h1>Esperando Pago</h1>
          <div style={{
            margin: '0 auto', display: 'flex', flexDirection: 'row', justifyContent: 'center',
          }}
          >
            <CircularProgress style={{ color: 'var(--global--secondary--BackgroundColor' }} />
          </div>
        </div>
      ) : (
        <div>
          <h1>Pago Cornershop</h1>
          <h2>
            $
            {thousandSeparator(total, '.')}
          </h2>
          <div className="rappi-payment-inputs">
            <input type="text" placeholder="Código Cornershop" value={cornershopCode} onChange={(e) => setCornershopCode(e.target.value)} />
            {inputOpen ? (
              <form onSubmit={() => handleSubmit(TRANSBANK_PROVIDER)} className="transbank-form" style={{ marginTop: '10px' }}>
                <input
                  className="transbank-input"
                  placeholder="Código Voucher Transbank"
                  value={transbankCode}
                  onChange={(e) => setTransbankCode(e.target.value)}
                  required
                />
                <button type="submit" className="pay-button">Pagar</button>
              </form>
            ) : payButton()}
          </div>
          <p style={{ color: 'red' }}>{codeError}</p>
        </div>
      )}
    </div>
  );
}

export default CornershopPayment;
