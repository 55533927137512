import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Modal } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import axios from '../../../utils/axios';
import './Recomendations.css';
// import { RECOMMENDED_PRICE_LIST_STORE } from '../../../utils/const';
import {
  addRecommendedProduct,
  addToCart,
  setRecommendedProductsLoaded,
  setRecommendedResponseId,
} from '../../../actions/customerActions';
import thousandSeparator from '../../../utils/thousandSeparator';
import track from '../../../utils/analytics';
import getStock from '../../../utils/getStock';
import { getSalePrices } from '../../../utils/getPrice';

const Recommendations = ({
  reOpen,
  setReOpen,
  setRecommendedWatchTime,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    id: userId,
    Store: store,
  } = useSelector((state) => state.account.user);
  const {
    cart,
    type: customerType,
    user,
    recommended_product: recommendedProducts,
    recommended_response_id: recommendedResponseId,
  } = useSelector((state) => state.customer);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const getRecommendedProducts = async () => {
    try {
      setLoading(true);
      setOpen(true);
      setRecommendedWatchTime((prev) => ({ ...prev, start: Date.now() }));

      const normalPriceList = store.price_list_id;
      const clubPriceList = store.club_price_list_id;

      const recommendedBody = {
        products: Object.keys(cart).map((k) => Number(k)),
        price_list: normalPriceList,
        club_price_list: clubPriceList,
        store_id: store.id,
        customer_id: user?.id || null,
        seller_id: Number(userId),
      };

      const recommendedProductsResponse = await axios
        .post('/api/customer/recommended-products', recommendedBody);

      if (recommendedProductsResponse?.data?.data?.products?.length) {
        const productsWithStock = [];

        recommendedProductsResponse.data.data.products.forEach((product) => {
          if ((getStock(product, cart) >= 1)) {
            const salePrices = getSalePrices(product, store, customerType);
            const newProduct = product;
            newProduct.salePrices = salePrices;
            productsWithStock.push(newProduct);
          }
        });

        if (productsWithStock.length) {
          dispatch(addRecommendedProduct(productsWithStock));
          dispatch(setRecommendedProductsLoaded(true));
          dispatch(setRecommendedResponseId(recommendedProductsResponse.data.data.responseId));
        } else {
          setOpen(false);
          setRecommendedWatchTime({ total: 0, start: 0 });
        }
      }
    } catch (err) {
      setOpen(false);
      setRecommendedWatchTime({ total: 0, start: 0 });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const isCustomer = user ? user.id : false;
    if (isCustomer && reOpen) {
      if (!recommendedProducts?.length) {
        getRecommendedProducts();
      } else {
        setOpen(true);
        setRecommendedWatchTime((prev) => ({ ...prev, start: Date.now() }));
      }
      setReOpen(false);
    }
  }, [reOpen]);

  const handleClose = () => {
    setOpen(false);
    setReOpen(false);
    setRecommendedWatchTime((prev) => ({
      ...prev,
      total: parseFloat((prev.total + ((Date.now() - prev.start) / 1000)).toFixed(3)),
    }));
    axios.post('/api/customer/exit-recommendpos', {
      response_id: recommendedResponseId,
      timestamp: Date.now(),
    }).catch((error) => console.log('Error exiting recommendations', error));
  };

  const addProductToCart = async (product, amount) => {
    if (!(getStock(product, cart) >= amount)) {
      const key = enqueueSnackbar(`${product.name} no tiene stock suficiente!`, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      // Get prices
      const prices = product.salePrices;

      // Get max quantities
      const maxQuantities = {
        clubGeneral: {
          min: 0,
          max: product.customer_limit_discount,
          disc: product.customer_discount,
        },
        bulk: {
          min: product.bulk_amount,
          max: 99999,
        },
        sellout: {
          min: 0,
          max: product.sellout.max_quantity,
        },
        recommended: {
          min: 0,
          max: product.max_recommended_quantity,
        },
        offers: {
          min: 0,
          max: 0,
        },
      };

      dispatch(addToCart(
        product,
        amount,
        prices,
        maxQuantities,
      ));

      const recommendedProductIds = recommendedProducts.map((rp) => rp.id);

      // Get product price list id
      const recommendedPriceListId = product.salePrices.recommended.price_list_id;

      // Send add recommended request
      axios.post('/api/customer/add-recommended', {
        product_id: [product.id],
        price_list_id: recommendedPriceListId,
        store_id: store.id,
        customer_id: user.id,
        response_id: recommendedResponseId,
        seller_id: Number(userId),
        timestamp: Date.now(),
      }).catch((error) => console.log('Error adding recommended product', error));

      if (recommendedProductIds.includes(product.id)) {
        // GA
        track.event('add_to_cart', {
          items: [{
            item_id: product.sku,
            item_name: product.name,
            creative_name: `Oferta personalizada ${product.sku}`,
            creative_slot: `Usuario ${user.rut}`,
            quantity: amount,
          }],
        });
      }

      const key = enqueueSnackbar(`${amount} ${product.name} en carro!`, {
        variant: 'success',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  };

  const renderPrices = (
    normalPrice = 0,
    recommendedPrice = 0,
  ) => {
    if (!recommendedPrice || !normalPrice) {
      setOpen(false);
      setLoading(false);
      return null;
    }

    // Show discount if it's more than 1%
    if ((1 - recommendedPrice / normalPrice) > 0.01) {
      return (
        <div className="recommended-price-wrapper">
          <div>
            <span className="recommended-price-normal" style={{ textDecoration: 'line-through' }}>
              {`$${thousandSeparator(normalPrice, '.')}`}
            </span>
            <span className="recommended-price-percentage">
              <b>{` -%${Math.round((1 - recommendedPrice / normalPrice) * 100)}`}</b>
            </span>
          </div>
          <span className="recommended-price-recommended">
            <b>{`$${thousandSeparator(recommendedPrice, '.')}`}</b>
          </span>
        </div>
      );
    }

    return (
      <div>
        <span><b>{`$${thousandSeparator(recommendedPrice, '.')}`}</b></span>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {loading ? (
        <div className="recommendation-loading">
          <CircularProgress
            style={{
              color: 'var(--global--secondary--BackgroundColor',
            }}
          />
        </div>
      ) : (
        <div className="recommendation-popover-container">
          <>
            <div className="recommendation-popover-header">
              <h3>Oferta personalizada</h3>
            </div>
            <div className="recommendation-popover-content">
              <div className="recommendation-items-container">
                {recommendedProducts.map((p) => (
                  <div key={p.id} className="recommendation-item">
                    <h4 className="recommendation-item-text">
                      {p.name}
                    </h4>
                    <div className="recommendation-item-price-button">
                      {renderPrices(
                        p.salePrices.normal.price,
                        p.salePrices.recommended.price,
                      )}
                      <button
                        className="recommendation-item-button"
                        type="button"
                        onClick={() => addProductToCart(p, 1)}
                      >
                        Agregar
                      </button>
                    </div>
                  </div>
                ))}
              </div>

            </div>
            <div className="recommendation-popover-footer">
              <button
                type="button"
                className="close-recommendation"
                onClick={() => handleClose()}
              >
                Salir
              </button>
            </div>
          </>
        </div>
      )}
    </Modal>
  );
};

export default Recommendations;
