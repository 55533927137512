import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import Page from '../../components/Layout/Page';
import './Return.css';
import '../FinalPay/FinalPay.css';
import { resetReturnId } from '../../actions/customerActions';
import axios from '../../utils/axios';
import { MERCADOPAGO_PROVIDER, REDELCOM_PROVIDER, TRANSBANK_SMARTPOS_PROVIDER } from '../../utils/const';

function ReturnFinal() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const returnId = useSelector((state) => state.customer.return_id);
  const { machineNumber, isMercadopagoMachine } = useSelector((state) => state.session);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [provider, setProvider] = useState('');
  const [providerVersion, setProviderVersion] = useState('');

  window.callPrinterWithResponse = async (resp) => {
    if (resp?.status === 0) {
      dispatch(resetReturnId());
      history.push('/inicio');
    } else {
      setError('Error imprimiendo nota de crédito. Por favor intente nuevamente.');
    }
    setLoading(false);
  };

  window.requestForPrinterResult = async (resp) => {
    if (resp?.response?.status === 0) {
      dispatch(resetReturnId());
      history.push('/inicio');
    } else {
      const key = enqueueSnackbar(JSON.stringify(resp), {
        variant: 'error',
        onClick: () => closeSnackbar(key),
      });
      setError('Error imprimiendo nota de crédito. Por favor intente nuevamente.');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (window.AppPagoSDK) {
      setProvider(TRANSBANK_SMARTPOS_PROVIDER);
      if (window.HARDWARESDK) setProviderVersion('pro');
      else setProviderVersion('normal');
    } else if (isMercadopagoMachine) {
      setProvider(MERCADOPAGO_PROVIDER);
    } else {
      setProvider(REDELCOM_PROVIDER);
    }
  }, []);

  const printMercadoPagoMachine = async (dte) => {
    const printCallback = (result, err) => {
      if (result === 'success') {
        dispatch(resetReturnId());
        setLoading(false);
        history.push('/inicio');
      } else {
        const key = enqueueSnackbar(JSON.stringify(err), {
          variant: 'error',
          onClick: () => closeSnackbar(key),
        });
        setError('Error imprimiendo nota de crédito. Por favor intente nuevamente.');
        setLoading(false);
      }
    };

    // eslint-disable-next-line no-undef
    launchCustomTag(dte, printCallback);
  };

  const printDocument = async () => {
    setLoading(true);
    setError(null);

    try {
      const resp = await axios.post('/api/returns/print', {
        return_id: returnId,
        machine_id: machineNumber,
        provider,
        provider_version: providerVersion,
      });

      if (resp.data.code === 200) {
        if (provider === TRANSBANK_SMARTPOS_PROVIDER) {
          const { lines = [] } = resp.data.data;
          if (window.HARDWARESDK) { // New sdk for printing
            window.HARDWARESDK.requestForPrinter(JSON.stringify(lines));
          } else {
            window.AppPagoSDK.callPrinterWith(JSON.stringify({ lines }));
          }
        } else if (provider === MERCADOPAGO_PROVIDER) {
          printMercadoPagoMachine(resp.data.data.printFormat);
        } else {
          setLoading(false);
          dispatch(resetReturnId());
          history.push('/inicio');
        }
      } else {
        setLoading(false);
        setError('Error imprimiendo nota de crédito. Por favor intente nuevamente.');
      }
    } catch (err) {
      const key = enqueueSnackbar(JSON.stringify(err.message), {
        variant: 'error',
        onClick: () => closeSnackbar(key),
      });
      setLoading(false);
      setError('Error imprimiendo nota de crédito. Por favor intente nuevamente.');
    }
  };

  return (
    <Page
      title="Club Líquidos | Nota de Credito"
      progressStep="none"
      backRef="/devolucion"
    >
      <div className="final-payment-result">
        <div>
          <h1>Nota de Crédito Generada</h1>
          <div className="final-payment-buttons">
            <button
              type="button"
              className={loading ? 'yellow-button disabled' : 'yellow-button'}
              disabled={loading}
              onClick={() => printDocument()}
            >
              Imprimir
            </button>
          </div>
          <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
          <div
            style={{
              margin: '0 auto',
              paddingTop: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              visibility: loading ? 'visible' : 'hidden',
            }}
          >
            <CircularProgress
              style={{ color: 'var(--global--secondary--BackgroundColor' }}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default ReturnFinal;
