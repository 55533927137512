import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import axios from '../../utils/axios';
import thousandSeparator from '../../utils/thousandSeparator';
import { setPaymentLoading } from '../../actions/customerActions';
import { MERCADOPAGO_PROVIDER, REDELCOM_PROVIDER, TRANSBANK_SMARTPOS_PROVIDER } from '../../utils/const';

function RappiPayment({
  handlePayed,
  payed,
  total,
  paymentStep,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { payment_loading: paymentLoading } = useSelector((state) => state.customer);
  const { Store: store } = useSelector((state) => state.account.user);

  const [rappiCode, setRappiCode] = useState(null);
  const orderId = useSelector((state) => state.customer.order_id);
  const { machineNumber, isMercadopagoMachine } = useSelector((state) => state.session);
  // const [loading, setLoading] = useState(false);
  const [codeError, setCodeError] = useState(null);

  useEffect(() => {
    if (payed) {
      handlePayed(null);
    }
  }, [payed, handlePayed]);

  const confirmPayment = async (rdcId) => {
    let paymentConfirmed = false;
    let error = null;

    while (!paymentConfirmed) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const resp = await axios.get(`/api/payments/redelcom/${rdcId}`);
        if (resp.status === 200) {
          const { code, data } = resp.data;

          if (code === 200) {
            paymentConfirmed = true;

            if (data.ESTADO === 'RECHAZADO') {
              error = 'El pago fue rechazado.';
            }
          } else if (code === 204) {
            paymentConfirmed = false;
          } else {
            paymentConfirmed = true;
            error = 'Ha ocurrido un error. Por favor intente nuevamente.';
          }
        } else {
          paymentConfirmed = true;
          error = 'Ha ocurrido un error. Por favor intente nuevamente.';
        }
      } catch (err) {
        console.log('Error confirming payment: ', err);
      }

      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 1500));
    }

    dispatch(setPaymentLoading(false));
    // setLoading(false);
    handlePayed(error);
  };

  const handleSubmit = async (provider) => {
    dispatch(setPaymentLoading(true));
    // setLoading(true);

    if (!rappiCode || rappiCode === '') {
      dispatch(setPaymentLoading(false));
      // setLoading(false);
      setCodeError('Por favor ingrese Código Rappi');
      return;
    }

    if (rappiCode.length < 8) {
      dispatch(setPaymentLoading(false));
      const key = enqueueSnackbar('Código incompleto', {
        variant: 'error',
        onClick: () => closeSnackbar(key),
      });
      return;
    }

    try {
      const resp = await axios.post('/api/payments/rappi/new', {
        order_id: orderId,
        code: rappiCode,
        machine_number: machineNumber,
        paymentId: paymentStep.id,
        provider,
      });

      const { payment } = resp.data.data;

      if (provider === REDELCOM_PROVIDER) {
        confirmPayment(payment.rdc_transaction_id);
      }
      if (provider === TRANSBANK_SMARTPOS_PROVIDER) {
        await axios.post('/api/payments/transbank-smartpos/new', {
          order_id: orderId,
          payment_id: paymentStep.id,
          transbank_code: null,
          smart_pos_transaction_id: null,
        });
        dispatch(setPaymentLoading(false));
        handlePayed(null);
      }
      if (provider === MERCADOPAGO_PROVIDER) {
        await axios.put('/api/payments/mercadopago/machine', {
          payment_id: paymentStep.id,
          machineTransactionId: null,
        });
        dispatch(setPaymentLoading(false));
        handlePayed(null);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.errorMessage || error.message;
      console.log('errorMessage: ', errorMessage);
      handlePayed(errorMessage);
    }
  };

  const payButton = () => {
    if (window.AppPagoSDK) {
      return (
        <>
          {store.tbk_smart_pos_active ? (
            <button
              disabled={paymentLoading}
              type="button"
              className="yellow-button"
              onClick={() => handleSubmit(TRANSBANK_SMARTPOS_PROVIDER)}
              style={{ fontWeight: 'bold' }}
            >
              PAGAR
            </button>
          ) : <span className="error-message">Tienda no habilitada</span>}
        </>
      );
    }

    if (isMercadopagoMachine) {
      return (
        <button
          disabled={paymentLoading}
          type="button"
          className="yellow-button"
          onClick={() => handleSubmit(MERCADOPAGO_PROVIDER)}
        >
          Pagar con MercadoPago
        </button>
      );
    }

    return (
      <button
        disabled={paymentLoading}
        type="button"
        className="yellow-button"
        onClick={() => handleSubmit(REDELCOM_PROVIDER)}
        style={{ marginTop: '30px', height: '55px' }}
      >
        Pagar con Redelcom
      </button>
    );
  };

  return (
    <div>
      {paymentLoading ? (
        <div>
          <h1>Esperando Pago</h1>
          <div style={{
            margin: '0 auto', display: 'flex', flexDirection: 'row', justifyContent: 'center',
          }}
          >
            <CircularProgress style={{ color: 'var(--global--secondary--BackgroundColor' }} />
          </div>
        </div>
      ) : (
        <div>
          <h1>Pago Rappi</h1>
          <h2>
            $
            {thousandSeparator(total, '.')}
          </h2>
          <div className="rappi-payment-inputs">
            <input type="number" placeholder="Código Rappi" value={rappiCode} onChange={(e) => setRappiCode(e.target.value)} />
            {payButton()}
          </div>
          <p style={{ color: 'red' }}>{codeError}</p>
        </div>
      )}
    </div>
  );
}

export default RappiPayment;
