/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@material-ui/core';
import { RefreshCw } from 'react-feather';
import Page from '../../components/Layout/Page';
import {
  resetRecommendedProduct,
  setRecommendedProductsLoaded,
} from '../../actions/customerActions';
import axios from '../../utils/axios';
import './DeliveryOrder.css';
import { MERCADOPAGO_PROVIDER, REDELCOM_PROVIDER, TRANSBANK_SMARTPOS_PROVIDER } from '../../utils/const';

function DeliveryOrder() {
  const { deliveryType } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { machineNumber, isMercadopagoMachine } = useSelector((state) => state.session);

  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [order, setOrder] = useState({});
  const [billingSuccess, setBillingSuccess] = useState(null);

  useEffect(() => {
    dispatch(setRecommendedProductsLoaded(false));
    dispatch(resetRecommendedProduct());
    if (!deliveryType) window.location.assign('/inicio');
  }, []);

  window.callPrinterWithResponse = async (resp) => {
    if (resp?.status === 0) {
      window.location.assign('/inicio');
    } else if (resp?.status === 2) {
      const errorMessage = resp.error || 'Falta papel en la impresora';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else if (resp?.status === 9) {
      const errorMessage = resp.error || 'Batería muy baja para imprimir';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      const errorMessage = resp.error || 'Ha ocurrido un error al imprimir';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
    setLoading(false);
  };

  window.requestForPrinterResult = async (resp) => {
    if (resp?.response?.status === 0) {
      window.location.assign('/inicio');
    } else if (resp?.response?.codeResponse === 2) {
      let errorMessage = resp.response?.glossResponse || 'Falta papel en la impresora';
      errorMessage += `(Cod ${resp.response.codeResponse})`;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else if (resp?.response?.codeResponse === 9) {
      let errorMessage = resp.response?.glossResponse || 'Batería muy baja para imprimir';
      errorMessage += `(Cod ${resp.response.codeResponse})`;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      let errorMessage = resp?.response?.glossResponse || 'Ha ocurrido un error al imprimir';
      if (resp?.response?.codeResponse) {
        errorMessage += `(Cod ${resp.response.codeResponse})`;
      }
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
    setLoading(false);
  };

  const printMercadoPagoMachine = async (dte) => {
    const printCallback = (result, err) => {
      if (result === 'success') {
        window.location.assign('/inicio');
      } else {
        const key = enqueueSnackbar(JSON.stringify(err), {
          variant: 'error',
          onClick: () => closeSnackbar(key),
        });
      }
      setLoading(false);
    };

    // eslint-disable-next-line no-undef
    launchCustomTag(dte, printCallback);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (!orderNumber) return null;

    if (orderNumber.length < 5) {
      const key = enqueueSnackbar('Código incompleto', {
        variant: 'error',
        onClick: () => closeSnackbar(key),
      });
      return null;
    }

    setLoading(true);
    try {
      const { data } = await axios.get(`/api/${deliveryType}/order/${orderNumber}`);
      if (data.order) setOrder(data.order);
    } catch (error) {
      const errorMessage = error.response?.data?.errorMessage || error.message;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => closeSnackbar(key),
      });
    }
    setLoading(false);
    return null;
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setOrderNumber(value);
  };

  const updateOrder = async () => {
    setLoadingUpdate(true);
    try {
      const { data } = await axios.get(`/api/${deliveryType}/order/update/${order.order_id}`);
      if (data) setOrder(data);
    } catch (error) {
      const errorMessage = error.response?.data?.errorMessage || error.message;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => closeSnackbar(key),
      });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const generateBill = async () => {
    setLoading(true);
    try {
      const billingResponse = await axios.patch(`/api/${deliveryType}/order/accept`, {
        orderId: order.order_id,
        machineNumber,
      });
      const { billingSuccess: billSuccess = true } = billingResponse.data || {};
      setBillingSuccess(billSuccess);
    } catch (error) {
      const errorMessage = error.response?.data?.errorMessage || error.message;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => closeSnackbar(key),
      });
    }

    try {
      const { data } = await axios.get(`/api/${deliveryType}/order/${orderNumber}`);
      if (data.order) setOrder(data.order);
    } catch (error) {
      const key = enqueueSnackbar('Ha ocurrido un error. Por favor ingrese el código nuevamente', {
        variant: 'error',
        onClick: () => closeSnackbar(key),
      });
    }
    setLoading(false);
  };

  const printBill = async () => {
    setLoading(true);
    try {
      let provider = '';
      let providerVersion = '';
      if (window.AppPagoSDK) {
        provider = TRANSBANK_SMARTPOS_PROVIDER;
        if (window.HARDWARESDK) {
          providerVersion = 'pro';
        } else {
          providerVersion = 'normal';
        }
      } else if (isMercadopagoMachine) {
        provider = MERCADOPAGO_PROVIDER;
      } else {
        provider = REDELCOM_PROVIDER;
      }
      const response = await axios.post('/api/payments/bill/print', {
        order_id: order.order_id,
        format: billingSuccess ? 'impresa' : 'voucher',
        email: null,
        provider,
        billing_success: billingSuccess,
        provider_version: providerVersion,
      });
      if (response.status === 200) {
        if (response.data.success) {
          if (provider === TRANSBANK_SMARTPOS_PROVIDER) {
            let { printFormat = [] } = response.data.data;
            if (window.HARDWARESDK) { // New sdk for printing
              window.HARDWARESDK.requestForPrinter(JSON.stringify(printFormat));
            } else {
              printFormat = JSON.stringify({ lines: printFormat });
              window.AppPagoSDK.callPrinterWith(printFormat);
            }
          }
          if (provider === MERCADOPAGO_PROVIDER) {
            printMercadoPagoMachine(response.data.data.printFormat);
          } else {
            setLoading(false);
            window.location.assign('/inicio');
          }
        } else {
          setLoading(false);
          const errorMessage = 'Ha ocurrido un error en la impresión. Por favor intente nuevamente';
          const key = enqueueSnackbar(errorMessage, {
            variant: 'error',
            onClick: () => closeSnackbar(key),
          });
        }
      } else {
        setLoading(false);
        const errorMessage = 'Ha ocurrido un error en la impresión. Por favor intente nuevamente';
        const key = enqueueSnackbar(errorMessage, {
          variant: 'error',
          onClick: () => closeSnackbar(key),
        });
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response?.data?.errorMessage || error.message;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => closeSnackbar(key),
      });
    }
  };

  const renderOrderDetail = () => (
    <div>
      <div className="delivery-order-detail-head">
        <h2>{`Pedido ${order.id}`}</h2>
        <h3>{`Estado: ${order.uberEatsOrder?.status || 'Sin estado'}`}</h3>
        <h3>Productos</h3>
      </div>
      <div className="delivery-order-table-wrapper">
        <table>
          <thead className="delivery-order-table-head">
            <tr>
              <th>SKU</th>
              <th>Nombre</th>
              <th>Cantidad</th>
              <th style={{ paddingLeft: '10px' }}>Stock</th>
            </tr>
          </thead>
          <tbody>
            {order.products.map((product, i) => (
              <tr key={`delivery-orders-list-${i}`}>
                <td className="delivery-order-table-row">{product.sku}</td>
                <td className="delivery-order-table-row" style={{ maxWidth: '150px' }}>{product.name}</td>
                <td className="delivery-order-table-row">{product.quantity}</td>
                <td
                  className={product.stock < product.quantity
                    ? 'delivery-order-table-row-no-stock'
                    : 'delivery-order-table-row'}
                >
                  {product.stock}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="delivery-order-generate-buttons">
        {!order.bill_id && (
          <>
            {billingSuccess === false ? (
              <button
                type="button"
                onClick={printBill}
                className="yellow-button"
                disabled={loading}
              >
                {loading && (
                  <CircularProgress
                    style={{
                      color: 'var(--global--primary--BackgroundColor',
                      width: '20px',
                      height: '20px',
                    }}
                  />
                )}
                {!loading && (<span>Imprimir comprobante</span>)}
              </button>
            ) : (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              >
                <button
                  type="button"
                  onClick={updateOrder}
                  className="yellow-button"
                  style={{
                    marginBottom: '20px',
                    height: '50px',
                    padding: '5px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  disabled={loadingUpdate || loading}
                >
                  {loadingUpdate && (
                    <CircularProgress
                      style={{
                        color: 'var(--global--primary--BackgroundColor',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  )}
                  {!loadingUpdate && (
                    <>
                      Actualizar
                      <RefreshCw style={{ marginLeft: '10px' }} width={25} height={25} />
                    </>
                  )}
                </button>
                <button
                  type="button"
                  onClick={generateBill}
                  className="yellow-button"
                  disabled={loadingUpdate || loading}
                >
                  {loading && (
                    <CircularProgress
                      style={{
                        color: 'var(--global--primary--BackgroundColor',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  )}
                  {!loading && (<span>Generar boleta</span>)}
                </button>
              </div>
            )}
          </>
        )}
        {order.bill_id && (
          <button
            type="button"
            onClick={printBill}
            className="yellow-button"
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                style={{
                  color: 'var(--global--primary--BackgroundColor',
                  width: '20px',
                  height: '20px',
                }}
              />
            )}
            {!loading && (<span>Imprimir boleta</span>)}
          </button>
        )}
      </div>
    </div>
  );

  return (
    <Page
      title={`Club Líquidos | Pedido ${deliveryType}`}
      progressStep="delivery-orders"
      backRef="/inicio"
    >
      <div className="delivery-order-wrapper">
        <h1>{`${deliveryType.toUpperCase()}`}</h1>
        {!order.id && (
          <div>
            {loading && (<span>Cargando...</span>)}
            {!loading && (
              <div>
                <h3>Ingrese el código del pedido</h3>
                <form
                  onSubmit={handleOnSubmit}
                  className="delivery-order-form"
                >
                  <input
                    required
                    placeholder="Código"
                    onChange={handleInputChange}
                    value={orderNumber}
                    className="delivery-order-input"
                  />
                  <button
                    type="submit"
                    className="delivery-order-form-button"
                  >
                    Buscar
                  </button>
                </form>
              </div>
            )}
          </div>
        )}
        {order.id && (
          <div className="delivery-container">
            {renderOrderDetail()}
          </div>
        )}
      </div>
    </Page>
  );
}

export default DeliveryOrder;
